<template>
  <div class="supplier_detail" v-loading="loading">
    <back />

    <div class="content globle_border">
      <el-form ref="form" class="form" :model="form" label-position="left" label-width="170px" disabled>
        <div class="title1">供应商信息</div>
        <el-form-item label="供应商名称">
          <el-input v-model="form.supplierName"></el-input>
        </el-form-item>
        <el-form-item label="营业执照">
          <el-image class="busilicense" fit="cover" :src="form.busiLicense" :preview-src-list="[form.busiLicense]"></el-image>
        </el-form-item>
        <el-form-item label="Logo">
          <el-image class="busilicense" fit="cover" :src="form.logoPic" :preview-src-list="[form.logoPic]"></el-image>
        </el-form-item>
        <el-form-item label="统一社会信用代码">
          <el-input v-model="form.creditCode"></el-input>
        </el-form-item>
        <el-form-item label="联系人">
          <el-input v-model="form.contacts"></el-input>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input v-model="form.telphone"></el-input>
        </el-form-item>
        <el-form-item label="供应商地址">
          <el-input v-model="form.local"></el-input>
        </el-form-item>
        <el-form-item label="供应商简介">
          <el-input type="textarea" v-model="form.supplierIntroduce" :autosize="{ minRows: 5, maxRows: 15 }"></el-input>
        </el-form-item>

        <div class="title2">财务信息</div>
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item label="财务负责人姓名">
              <el-input v-model="form.financeName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="财务负责人电话">
              <el-input v-model="form.financePhone"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item label="企业支付宝账号">
              <el-input v-model="form.zfbAccount"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="真实姓名">
              <el-input v-model="form.realName"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <div class="title2">
          <span>应标资格信息</span>
          <span class="more" @click="showMore">
            查看更多
            <i class="el-icon-arrow-right"></i>
          </span>
        </div>
        <div class="globle_table">
          <el-table :data="tableData" style="width: 100%">
            <el-table-column prop="number" label="序号" width="80">
              <template slot-scope="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column label="应标资格" show-overflow-tooltip>
              <template slot-scope="scope">
                <span v-if="scope.row.eligibleType == 1">平台资格</span>
                <span v-if="scope.row.eligibleType == 2">独立资格</span>
              </template>
            </el-table-column>
            <el-table-column prop="deliveryArea" label="配送区域" show-overflow-tooltip></el-table-column>
            <el-table-column prop="cooperateCollege" label="合作院校" show-overflow-tooltip></el-table-column>
            <el-table-column prop="truckNumber" label="货车数量"></el-table-column>
            <el-table-column prop="eligiblePrice" label="金额"></el-table-column>
            <el-table-column label="有效期区间" width="220">
              <template slot-scope="scope">
                <span>{{ scope.row.startTime }} 至 {{ scope.row.endTime }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      id: null,
      tableData: [],
      form: {
        supplierName: "",
        contacts: "",
        logoPic: "",
        telphone: "",
        supplierIntroduce: "",
        busiLicense: "",
        creditCode: "",
        financeName: "",
        financePhone: "",
      },
    }
  },
  created() {
    this.id = this.$route.query.id
    this.getSupplierDetail()
  },
  methods: {
    // 应标资格列表
    getPortalBiddingEligibleList() {
      this.$axios
        .get(this.$api.portalBiddingEligibleList, {
          params: {
            supplierName: this.form.supplierName,
            page: 1,
            pageSize: 3,
          },
        })
        .then(res => {
          this.tableData = res.data.result.list
        })
    },
    // 详情
    getSupplierDetail() {
      this.loading = true
      this.$axios
        .get(`${this.$api.supplierInfoDetails}/${this.id}`)
        .then(res => {
          const { result } = res.data
          this.form = result
          this.form.local = result.province + result.city + result.area + result.address
          this.loading = false
          this.getPortalBiddingEligibleList()
        })
        .catch(() => {
          this.loading = false
        })
    },
    showMore() {
      localStorage.removeItem("lastDetailPath")
      this.$store.commit("setSideMenuActive", "/biddingManagement/applicationExamine")
      this.$router.push({ path: "/biddingManagement/applicationExamine", query: { auditValue: 1, nameValue: this.form.supplierName } })
    },
  },
}
</script>

<style scoped lang="scss">
.supplier_detail {
  .content {
    padding: 60px 190px;

    .form {
      width: 900px;
      margin: 0 auto;

      .title1,
      .title2 {
        position: relative;
        font-weight: bold;
        margin-bottom: 40px;
      }

      .title1::before,
      .title2::before {
        content: "";
        position: absolute;
        top: 0;
        left: -12px;
        width: 4px;
        height: 22px;
        background-color: #069bbc;
      }

      .title2 {
        margin-top: 60px;

        .more {
          color: #069bbc;
          margin-left: 60px;
          cursor: pointer;
        }
      }

      ::v-deep .el-form-item__label {
        color: #272727;
        font-size: 16px;
        padding-right: 40px;
      }

      ::v-deep .el-input__inner,
      ::v-deep .el-textarea__inner {
        background: #fff;
        border-radius: 10px;
        border: 1px solid #e5e7e6;
      }

      ::v-deep .el-input.is-disabled .el-input__inner,
      ::v-deep .el-textarea.is-disabled .el-textarea__inner {
        color: #1a1a1a;
      }

      .logo {
        display: block;
        width: 100px;
        height: 100px;
        border-radius: 4px;
      }

      .busilicense {
        display: block;
        width: 133px;
        height: 100px;
        border-radius: 4px;
      }
    }
  }
}
</style>
