import { render, staticRenderFns } from "./supplierDetail.vue?vue&type=template&id=30ec1155&scoped=true"
import script from "./supplierDetail.vue?vue&type=script&lang=js"
export * from "./supplierDetail.vue?vue&type=script&lang=js"
import style0 from "./supplierDetail.vue?vue&type=style&index=0&id=30ec1155&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30ec1155",
  null
  
)

export default component.exports